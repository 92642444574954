import * as React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import * as Styles from "../styles/charge.module.scss"

const ChargePage = () => {
    const DATA = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata { 
                        divisionJa { support },
                        menuJa { charge }
                    }
                }
            }
        `
    )
    const CHARGE_JA = DATA.site.siteMetadata.menuJa.charge
    return (
        <Layout division='support'>
            <Seo title={`${CHARGE_JA} | ${DATA.site.siteMetadata.divisionJa.support}`} />
            <h1 className="support">{CHARGE_JA}</h1>

            <div className={Styles.support}>
                <div className={Styles.table}>
                    <div className={Styles.table_body}>
                        <h2 className="support">相談</h2>
                        <div className={Styles.table_col}>
                            <span>初回</span>
                            <span>1,000円/回</span>
                            <span>受任時着手金に充当</span>
                        </div>
                        <div className={Styles.table_col}>
                            <span>2回目以降</span>
                            <span>5,000円/回</span>
                            <span>受任時着手金に充当</span>
                        </div>
                        <div className={Styles.table_col}>
                            <span>提携</span>
                            <span>30,000円～/月</span>
                            <span>
                                相談回数制限なし<br />
                                契約中他の料金1割引
                            </span>
                        </div>
                    </div>
                </div>
                <div className={Styles.table}>
                    <div className={Styles.table_body}>
                        <h2 className="support">IT導入支援</h2>
                        <div className={Styles.table_col}>
                            <span>調査</span>
                            <span>10,000円～/回</span>
                        </div>
                        <div className={Styles.table_col}>
                            <span>導入支援</span>
                            <span>20,000円～/回</span>
                        </div>
                        <div className={Styles.table_col}>
                            <span>継続支援</span>
                            <span>20,000円～/月</span>
                        </div>
                    </div>
                </div>
                <div className={Styles.plan_button}>
                    <a href="../../plan#itweb" target="_blank">
                        <button>お得なプランのご案内</button>
                    </a>
                </div>
                <div className={Styles.table}>
                    <div className={Styles.table_body}>
                        <h2 className="support">WEBサイト</h2>
                        <div className={Styles.table_col}>
                            <span>作成</span>
                            <span>100,000円～</span>
                        </div>
                        <div className={Styles.table_col}>
                            <span>リニューアル</span>
                            <span>50,000円～</span>
                        </div>
                        <div className={Styles.table_col}>
                            <span>管理</span>
                            <span>20,000円～/月</span>
                        </div>
                    </div>
                </div>
                <div className={Styles.plan_button}>
                    <a href="../../plan#itweb" target="_blank">
                        <button>お得なプランのご案内</button>
                    </a>
                </div>
                <div className={Styles.table}>
                    <div className={Styles.table_body}>
                        <h2 className="support">事務代行</h2>
                        <div className={Styles.table_col}>
                            <span>おつかい</span>
                            <span>5,000円/回</span>
                            <span>事務所近隣のみ</span>
                        </div>
                        <div className={Styles.table_col}>
                            <span>その他事務</span>
                            <span>応相談</span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ChargePage
